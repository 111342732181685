import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import augmentAdventCalendarEntry from "./data/augmentAdventCalendarEntry";
import entries from "./data/data";
import getCurrentDate from "./getCurrentDate";

ReactDOM.render(
  <React.StrictMode>
    <App entries={entries.map(augmentAdventCalendarEntry(getCurrentDate()))} />
  </React.StrictMode>,
  document.getElementById("root")
);
