import * as React from "react";
import { AdventCalendarEntry } from "../data/data";
import "./Entry.css";
import ClosedEntry from "./ClosedEntry";
import OpenEntry from "./OpenEntry";

type EntryProps = {
  entry: AdventCalendarEntry;
};

const Entry: React.FC<EntryProps> = (props) => (
  <div className="entry">
    {props.entry.open ? (
      <OpenEntry entry={props.entry} />
    ) : (
      <ClosedEntry day={props.entry.day} />
    )}
  </div>
);

export default Entry;
