export type AdventCalendarEntry = {
  artist: string;
  album: string;
  song: string;
  fileUrl: string;
  coverUrl: string;
  day: number;
  link: string;
  open: boolean;
};

const entries: AdventCalendarEntry[] = [
  {
    artist: "Subduer",
    album: "Death Monolith",
    song: "Death Monolith",
    fileUrl: "",
    coverUrl: "",
    day: 1,
    link: "https://www.metal-archives.com/albums/Subduer/Death_Monolith/700312",
    open: false,
  },
  {
    artist: "Celestial Bodies",
    album: "Spit Forth from Chaos",
    song: "The Final Covenant",
    fileUrl: "",
    coverUrl: "",
    day: 2,
    link: "https://anticosmicwarfare.bandcamp.com/album/spit-forth-from-chaos",
    open: false,
  },
  {
    artist: "Revenge",
    album: "Strike.Smother.Dehumanize",
    song: "Reaper Abyss (Real Rain)",
    fileUrl: "",
    coverUrl: "",
    day: 3,
    link:
      "https://www.metal-archives.com/albums/Revenge/Strike.Smother.Dehumanize/827115",
    open: false,
  },
  {
    artist: "Kapala",
    album: "Termination Apex",
    song: "Martial Dominance",
    fileUrl: "",
    coverUrl: "",
    day: 4,
    link:
      "https://www.metal-archives.com/albums/Kapala/Termination_Apex/767081",
    open: false,
  },
  {
    artist: "Jyotiṣavedāṅga",
    album: "Thermogravimetry Warp Continuum",
    song: "Protocol: Hyper Sterilization Initialize",
    fileUrl: "",
    coverUrl: "",
    day: 5,
    link:
      "https://www.metal-archives.com/albums/Jyoti%E1%B9%A3aved%C4%81%E1%B9%85ga/Thermogravimetry_Warp_Continuum/700982",
    open: false,
  },
  {
    artist: "Ululatum Tollunt",
    album: "Quantum Noose of Usurpation",
    song: "Quantum Noose of Usurpation",
    fileUrl: "",
    coverUrl: "",
    day: 6,
    link:
      "https://www.metal-archives.com/albums/Ululatum_Tollunt/Quantum_Noose_of_Usurpation/621381",
    open: false,
  },
  {
    artist: "Tetragrammacide",
    album: "Primal Incinerators of Moral Matrix",
    song:
      "Meontological Marga of Misanthropic Computation & Extensive Backwards  Physics",
    fileUrl: "",
    coverUrl: "",
    day: 7,
    link:
      "https://www.metal-archives.com/albums/Tetragrammacide/Primal_Incinerators_of_Moral_Matrix/665657",
    open: false,
  },
  {
    artist: "Infernal Curse",
    album: "Apocalipsis",
    song: "Sin of Iblis",
    fileUrl: "",
    coverUrl: "",
    day: 8,
    link:
      "https://www.metal-archives.com/albums/Infernal_Curse/Apocalipsis/557027",
    open: false,
  },
  {
    artist: "Mefitic",
    album: "Woes of Mortal Devotion",
    song: "Noxious Epiclesis",
    fileUrl: "",
    coverUrl: "",
    day: 9,
    link:
      "https://www.metal-archives.com/albums/Mefitic/Woes_of_Mortal_Devotion/497030",
    open: false,
  },
  {
    artist: "Chrworsch",
    album: "Ein Totenspruch zur viehischen Begattung",
    song: "Ein Totenspruch zur viehischen Begattung",
    fileUrl: "",
    coverUrl: "",
    day: 10,
    link:
      "https://www.metal-archives.com/albums/Chrworsch/Ein_Totenspruch_zur_viehischen_Begattung/544361",
    open: false,
  },
  {
    artist: "Sect Pig",
    album: "Slave Destroyed",
    song: "Temple Escape",
    fileUrl: "",
    coverUrl: "",
    day: 11,
    link:
      "https://www.metal-archives.com/albums/Sect_Pig/Slave_Destroyed/380321",
    open: false,
  },
  {
    artist: "Nuclearhammer",
    album: "Serpentine Hermetic Lucifer",
    song: "Phosphorus Clouds Descend on Mecca",
    fileUrl: "",
    coverUrl: "",
    day: 12,
    link:
      "https://www.metal-archives.com/albums/Nuclearhammer/Serpentine_Hermetic_Lucifer/404923",
    open: false,
  },
  {
    artist: "Knelt Rote",
    album: "Trespass",
    song: "Hunger",
    fileUrl: "",
    coverUrl: "",
    day: 13,
    link: "https://www.metal-archives.com/albums/Knelt_Rote/Trespass/355943",
    open: false,
  },
  {
    artist: "Wrathprayer",
    album:
      "The Sun of Moloch: the Sublimation of Sulphur's Essence Which Spawned Death and Life",
    song: "From the Depths of the Phlegethon",
    fileUrl: "",
    coverUrl: "",
    day: 14,
    link:
      "https://www.metal-archives.com/albums/Wrathprayer/The_Sun_of_Moloch%3A_The_Sublimation_of_Sulphur's_Essence_Which_Spawned_Death_and_Life/343607",
    open: false,
  },
  {
    artist: "Impiety",
    album: "Ravage & Conquer",
    song: "Weaponized",
    fileUrl: "",
    coverUrl: "",
    day: 15,
    link:
      "https://www.metal-archives.com/albums/Impiety/Ravage_%26_Conquer/332724",
    open: false,
  },
  {
    artist: "Thorybos",
    album: "Monuments of Doom Revealed",
    song: "Iudaea Capta",
    fileUrl: "",
    coverUrl: "",
    day: 16,
    link:
      "https://www.metal-archives.com/albums/Thorybos/Monuments_of_Doom_Revealed/338784",
    open: false,
  },
  {
    artist: "Irradiate",
    album: "R.D.D.",
    song: "Radiological Terror",
    fileUrl: "",
    coverUrl: "",
    day: 17,
    link: "https://www.metal-archives.com/albums/Irradiate/R.D.D./236588",
    open: false,
  },
  {
    artist: "Disforterror",
    album: "Impalement and Holocaust Stench",
    song: "March to the Apocalypse",
    fileUrl: "",
    coverUrl: "",
    day: 18,
    link:
      "https://www.metal-archives.com/albums/Disforterror/Impalement_and_Holocaust_Stench/220875",
    open: false,
  },
  {
    artist: "مدار",
    album: "Triumph Through Spears of Sacrilege",
    song: "Preaching for Mass Suicide",
    fileUrl: "",
    coverUrl: "",
    day: 19,
    link:
      "https://www.metal-archives.com/albums/%D8%AF%D9%85%D8%A7%D8%B1/Triumph_Through_Spears_of_Sacrilege/113420",
    open: false,
  },
  {
    artist: "Truppensturm",
    album: "Fields of Devastation",
    song: "Inflame the Pentacle",
    fileUrl: "",
    coverUrl: "",
    day: 20,
    link:
      "https://www.metal-archives.com/albums/Truppensturm/Fields_of_Devastation/178807",
    open: false,
  },
  {
    artist: "Deiphago",
    album: "Satanik Eon",
    song: "Satanist's War",
    fileUrl: "",
    coverUrl: "",
    day: 21,
    link: "https://www.metal-archives.com/albums/Deiphago/Satanik_Eon/159419",
    open: false,
  },
  {
    artist: "Axis of Advance",
    album: "Obey",
    song: "Of Due to Conflict It",
    fileUrl: "",
    coverUrl: "",
    day: 22,
    link: "https://www.metal-archives.com/albums/Axis_of_Advance/Obey/50911",
    open: false,
  },
  {
    artist: "Black Witchery",
    album: "Desecration of the Holy Kingdom",
    song: "Desecration of the Holy Kingdom",
    fileUrl: "",
    coverUrl: "",
    day: 23,
    link:
      "https://www.metal-archives.com/albums/Black_Witchery/Desecration_of_the_Holy_Kingdom/12237",
    open: false,
  },
  {
    artist: "Conqueror",
    album: "War Cult Supremacy",
    song: "Infinite Majesty",
    fileUrl: "",
    coverUrl: "",
    day: 24,
    link:
      "https://www.metal-archives.com/albums/Conqueror/War_Cult_Supremacy/8661",
    open: false,
  },
];

export default entries;
