import * as React from "react";
import "./ClosedEntry.css";

type ClosedEntryProps = {
  day: number;
};

const ClosedEntry: React.FC<ClosedEntryProps> = (props) => (
  <>
    <div className="day">{props.day}</div>
    <img className="cover-art" src="/wcs.png" alt="goat rider" />
  </>
);

export default ClosedEntry;
