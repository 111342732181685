import * as React from "react";
import "./CoverArt.css";

type CoverArtProps = {
  coverUrl: string;
  album: string;
};

const CoverArt: React.FC<CoverArtProps> = (props) => (
  <img className="cover-art" src={props.coverUrl} alt={props.album} />
);

export default CoverArt;
