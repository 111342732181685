import * as React from "react";
import { AdventCalendarEntry } from "../data/data";
import CoverArt from "./CoverArt";
import Description from "./Description";
import "react-h5-audio-player/lib/styles.css";
import "./OpenEntry.css";
import AudioPlayer from "react-h5-audio-player";

type OpenEntryProps = {
  entry: AdventCalendarEntry;
};

const OpenEntry: React.FC<OpenEntryProps> = (props) => (
  <>
    <CoverArt coverUrl={props.entry.coverUrl} album={props.entry.album} />
    <Description
      day={props.entry.day}
      song={props.entry.song}
      artist={props.entry.artist}
      link={props.entry.link}
      album={props.entry.album}
    />
    <AudioPlayer
      className="player"
      src={props.entry.fileUrl}
      autoPlay={false}
      showSkipControls={false}
      customAdditionalControls={[]}
      customVolumeControls={[]}
    />
  </>
);

export default OpenEntry;
