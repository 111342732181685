const getCurrentDate = (): Date => {
  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);
  const debugDay = params.get("debugDay");
  return debugDay
    ? new Date(`2020-12-${debugDay.padStart(2, "0")}T00:00:01`)
    : new Date();
};

export default getCurrentDate;
