import { AdventCalendarEntry } from "./data";

const padZero = (n: number): string => String(n).padStart(2, "0");

export const isOpen = (day: number, now: Date): boolean => {
  const entryDay = new Date(`2020-12-${padZero(day)}T00:00:01`).getTime();
  const today = now.getTime();
  return entryDay <= today;
};

const augmentAdventCalendarEntry: (
  now: Date
) => (entry: AdventCalendarEntry) => AdventCalendarEntry = (now) => (entry) => {
  const paddedDay = padZero(entry.day);
  return {
    ...entry,
    fileUrl: `/tracks/${paddedDay}.mp3`,
    coverUrl: `/cover/${paddedDay}.jpg`,
    open: isOpen(entry.day, now),
  };
};

export default augmentAdventCalendarEntry;
