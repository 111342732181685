import React from "react";
import "./App.css";
import { AdventCalendarEntry } from "./data/data";
import Entry from "./components/Entry";

const App: React.FC<{ entries: AdventCalendarEntry[] }> = (props) => {
  return (
    <div className="App">
      <div className="container">
        <div className="row">
          {props.entries.map((entry) => (
            <div key={entry.day} className="col-xl-4 col-lg-6 col-12">
              <Entry entry={entry} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default App;
