import * as React from "react";
import "./Description.css";

type DescriptionProps = {
  day: number;
  song: string;
  artist: string;
  link: string;
  album: string;
};

const Description: React.FC<DescriptionProps> = (props) => (
  <div className="description">
    {`${props.day}: "${props.song}" by ${props.artist} from `}
    <a target="_blank" rel="noopener" href={props.link}>
      {props.album}
    </a>
  </div>
);

export default Description;
